import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/img/scan_qrcode.svg'


const _hoisted_1 = { class: "pageContainer bg_lightGrey hostessScan p-5" }
const _hoisted_2 = { class: "scan" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = { class: "stream" }
const _hoisted_5 = { class: "d-flex aling-items-center" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_qr_scan = _resolveComponent("qr-scan")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 0,
          localizedKey: 'app_ScanFor' + _ctx.stageTitle,
          text: 'Scan for ' + _ctx.stageTitle,
          type: "h1",
          class: "hostessTitle"
        }, null, 8, ["localizedKey", "text"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_qr_scan, { callback: _ctx.callback }, null, 8, ["callback"])
      ])
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 1,
          localizedKey: "app_CheckByEmail",
          text: "Check by email",
          type: "h1",
          class: "hostessTitle"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_form_field, {
        type: "email",
        modelValue: _ctx.emailForCheck,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailForCheck) = $event)),
        placeholderKey: "backoffice_Email",
        placeholderValue: "Email",
        labelKey: "backoffice_Email",
        labelValue: "Email",
        required: ""
      }, null, 8, ["modelValue"]),
      _createElementVNode("button", {
        class: "primaryButton w-100 mt-4 ms-2",
        disabled: !_ctx.emailForCheck,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkByEmail()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Verifica",
          text: "Verifica"
        })
      ], 8, _hoisted_6)
    ])
  ]))
}