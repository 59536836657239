
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, ChallengeClient, GameClient } from '@/services/Services';
import QrScan from '@/components/QrScan.vue';
import ErrorModal from '../modals/errorModal.vue';
import ChallengeCompletedModal from '../modals/challengeCompletedModal.vue';

@Options({
    components: {
        QrScan
    }
})
export default class ScanForChallengeCompleted extends Vue {

    stageTitle: string = "";
    stageIdentifier: string = "";
    userIdentifier: string = "";

    emailForCheck: string = "";
    loaded: boolean = false;
    
    created() {
        this.stageIdentifier = this.$route.params.stageIdentifier.toString();

        ChallengeClient.getById(this.stageIdentifier)
        .then(x => {
            this.stageTitle = this.$localizationService.getTextFromValues(x.title.values);
            this.loaded = true;
        })
    }

    checkByEmail() {
        AppUserClient.getUserIdentifierBy(this.emailForCheck)
        .then(x => {
            if(!x) {
                this.$opModal.show(ErrorModal, {
                    title: "Utente non trovato!",
                    text: "L'utente non fa parte della community o non ha ancora fatto accesso alla webapp dell'evento",
                    callback: () => {
                        window.location.reload();
                    }
                })
            }
            else {
                this.userIdentifier = x;
                this.setChallengeCompleted();
            }
        })
    }

    callback(data: string) {
        if(!data.trim())
            return;

        this.userIdentifier = data;
        this.setChallengeCompleted();
    }

    setChallengeCompleted() {
        GameClient.stageCompleted(this.stageIdentifier, this.userIdentifier)
        .then(x => {
            this.$opModal.show(ChallengeCompletedModal, {
                callback: () => {
                    window.location.reload();
                },
                homeCallback: () => {
                    this.$opModal.closeLast();
                    this.$router.replace("/dashboard");
                }
            })
        })
    }

}
